import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  /* stylelint-disable */
  .ReactModal__Body--open,
  .ReactModal__Html--open {
    overflow: hidden;
  }
  /* stylelint-enable */
`;
