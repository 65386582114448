import { Colors } from "../../themes/Default";

import { Container, Spinner } from "./styles";

interface Props {
  className?: string;
  color?: string;
  size?: number;
}

export const LoadingSpinner = ({
  className,
  color = Colors.Astronaut,
  size = 1,
}: Props) => (
  <Container className={className} size={size}>
    <Spinner color={color} size={size} />
  </Container>
);
