import { gql } from "@apollo/client";

// We cannot use the Apollo client inside the link middleware so these need to be JSON
export const REFRESH_TOKEN_MUTATION = () =>
  JSON.stringify({
    query: `mutation { refreshToken { token payload } }`,
  });

const REFRESH_TOKEN = gql`
  mutation refreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      refreshToken
      refreshExpiresIn
      payload
    }
  }
`;

export default REFRESH_TOKEN;
