import { ReactNode } from "react";

import { Container } from "./styles";

export type AlertState = "negative" | "neutral" | "positive" | "warning";

interface Props {
  children: ReactNode;
  className?: string;
  state?: AlertState;
}

export const Alert = ({ children, className, state = "neutral" }: Props) => (
  <Container className={className} state={state}>
    {children}
  </Container>
);
