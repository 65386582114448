import { Container, Image } from "./styles";

interface Props {
  alt?: string;
  background?: string;
  className?: string;
  height?: number;
  src?: string | null;
  width?: number;
}

export const ResponsiveImage = ({
  alt,
  background,
  className,
  height = 9,
  src,
  width = 16,
}: Props) => {
  return (
    <Container
      cHeight={height}
      className={className}
      cWidth={width}
      background={background}
    >
      {src && <Image alt={alt} src={src} />}
    </Container>
  );
};
