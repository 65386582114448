import styled, { css, keyframes } from "styled-components";

interface ContainerProps {
  size: number;
}

interface SpinnerProps {
  color: string;
  size: number;
}

export const Container = styled.div<ContainerProps>`
  ${({ size }) => css`
    height: ${size}rem;
    width: ${size}rem;
  `}
`;

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div<SpinnerProps>`
  animation: ${rotate} 1.5s linear;
  animation-iteration-count: infinite;
  border-radius: 50%;
  margin: auto;
  position: absolute;
  transition: opacity 250ms;

  ${({ color, size }) => css`
    border: 0.25rem solid ${color};
    border-top-color: transparent;
    height: ${size}rem;
    width: ${size}rem;
  `}
`;
